import React, { FC, useMemo } from 'react'
import { graphql } from 'gatsby'

import HomePageType from 'types/HomePageType'
import Hero from 'components/Hero'
import {
  BaseGalleryType,
  BaseHeroType,
  PartnersType,
  SpeakersType,
  ScheduleType,
  SignupType,
  AgendaType,
  FestivalInNumbersType,
  VideoSectionType,
} from 'types'
import Partners from 'components/Partners'
import Gallery from 'components/Gallery'
import Speakers from 'components/Speakers'
import Schedule from 'components/Schedule'
import Signup from 'components/Signup'
import Agenda from 'components/Agenda'
import FestivalInNumbers from 'components/FestivalInNumbers'
import VideoSection from 'components/VideoSection'
import getPolishHomepage from 'utils/getPolishHomepage'
import { useLanguageContext } from 'contexts/LanguageContext'
import getEnglishHomepage from 'utils/getEnglishHomepage'

const HomePage: FC<HomePageType> = ({ location, data }) => {
  const { language } = useLanguageContext()

  const homepage = language === 'pl' ? getPolishHomepage(data) : getEnglishHomepage(data)

  const content = useMemo(
    () =>
      homepage?.map((component) => {
        if (!component?.isSectionVisible) {
          return null
        }
        switch (component?.strapi_component) {
          case 'base.partners-slider': {
            const partnersSection = component as PartnersType
            return (
              <Partners
                key={partnersSection.id}
                partners={partnersSection.partners}
                sectionTitle={partnersSection.sectionTitle}
              />
            )
          }
          case 'base.hero': {
            const hero = component as BaseHeroType
            return <Hero key={hero.id} {...hero} />
          }
          case 'base.galery-slider': {
            const gallery = component as BaseGalleryType
            return <Gallery key={gallery.id} {...gallery} />
          }
          case 'base.speakers-grid': {
            const speakers = component as SpeakersType
            return <Speakers key={speakers.id} {...speakers} />
          }
          case 'base.schedule': {
            const schedule = component as ScheduleType
            return <Schedule key={schedule.id} {...schedule} />
          }
          case 'base.signup-grid': {
            const signup = component as SignupType
            return <Signup key={signup.id} {...signup} />
          }
          case 'base.agenda': {
            const agenda = component as AgendaType
            return <Agenda key={agenda.id} {...agenda} location={location} />
          }
          case 'base.festival-in-numbers': {
            const achievements = component as FestivalInNumbersType
            return <FestivalInNumbers key={achievements.id} {...achievements} />
          }
          case 'base.video-section': {
            const section = component as VideoSectionType
            return <VideoSection {...section} />
          }
          default:
            return null
        }
      }),
    [homepage, location],
  )

  return <>{content}</>
}

export const query = graphql`
  {
    strapiHomepage {
      homepage {
        ... on STRAPI__COMPONENT_BASE_GALERY_SLIDER {
          id
          title
          buttonUrl
          buttonText
          images {
            id
            url
            alternativeText
          }
          strapi_component
          isSectionVisible
        }
        ... on STRAPI__COMPONENT_BASE_HERO {
          id
          backgroundColor
          buttonUrl
          buttonText
          subtitle
          subtitlePos
          title
          videoId
          footer
          backgroundVideo {
            webm {
              url
            }
            mp4 {
              url
            }
          }
          strapi_component
          isSectionVisible
          backgroundImage {
            url
          }
        }
        ... on STRAPI__COMPONENT_BASE_PARTNERS_SLIDER {
          id
          sectionTitle
          strapi_component
          isSectionVisible
          partners {
            index
            id
            Name
            WebsiteURL
            Logo {
              url
            }
            whiteLogo {
              url
            }
          }
        }
        ... on STRAPI__COMPONENT_BASE_SIGNUP_GRID {
          id
          signupImage {
            url
          }
          bannerImage {
            url
          }
          strapi_component
          isSectionVisible
          signup_sections {
            buttonText
            buttonUrl
            title
            subtitle
            link
            localizations {
              data {
                attributes {
                  buttonText
                  buttonUrl
                  link
                  subtitle
                  title
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BASE_SCHEDULE {
          id
          scheduleTitle
          strapi_component
          isSectionVisible
          events {
            id
            title
            startHour
            endHour
            date
            backgroundColor
            displayTitleOnDesktop
            link
            locale
            logo {
              url
            }
            localizations {
              data {
                attributes {
                  title
                  startHour
                  endHour
                  displayTitleOnDesktop
                  date
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BASE_SIGNUP_GRID {
          id
          signupImage {
            url
          }
          bannerImage {
            url
          }
          strapi_component
          isSectionVisible
          signup_sections {
            buttonText
            buttonUrl
            title
            subtitle
            link
          }
        }
        ... on STRAPI__COMPONENT_BASE_AGENDA {
          id
          title
          subtitle
          strapi_component
          isSectionVisible
          lectures {
            title
            subtitle
            room
            startHour
            backgroundColor
            logo {
              url
              alternativeText
            }
            localizations {
              data {
                attributes {
                  title
                  subtitle
                  startHour
                  room
                }
              }
            }
          }
          speakers {
            firstName
            lastName
            title
            photo {
              url
              alternativeText
            }
            description
            position
            linkedinUrl
            twitterUrl
            linktrUrl
            backgroundColor
            localizations {
              data {
                attributes {
                  bio
                  description
                  firstName
                  lastName
                  position
                  title
                }
              }
            }
            bio
          }
        }
        ... on STRAPI__COMPONENT_BASE_FESTIVAL_IN_NUMBERS {
          id
          strapi_component
          isSectionVisible
          backgroundImages {
            id
            url
            alternativeText
          }
          achievements {
            id
            name
            number
            index
            locale
            localizations {
              data {
                attributes {
                  name
                  number
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BASE_VIDEO_SECTION {
          id
          title
          article
          footer
          isSectionVisible
          videoId
          strapi_component
        }
        ... on STRAPI__COMPONENT_BASE_SPEAKERS_GRID {
          id
          sectionTitle
          sectionSubtitle
          strapi_component
          isSectionVisible
          speakers {
            index
            firstName
            lastName
            title
            photo {
              url
            }
            description
            position
            linkedinUrl
            twitterUrl
            linktrUrl
            backgroundColor
            localizations {
              data {
                attributes {
                  twitterUrl
                  title
                  position
                  linktrUrl
                  linkedinUrl
                  lastName
                  firstName
                  description
                  bio
                  backgroundColor
                }
              }
            }
            bio
          }
        }
      }
      localizations {
        data {
          attributes {
            homepage {
              videoId
              title
              subtitlePos
              subtitle
              sectionTitle
              sectionSubtitle
              scheduleTitle
              footer
              buttonUrl
              buttonText
              article
              _xcomponent
            }
          }
        }
      }
    }
  }
`

export default HomePage
